import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';

import { SEO, Layout, PagePresentation, InstallationPresentation, Services } from '@/components';

import { PageContext } from '@/domain/pageContext';
import { PInstallation } from '@/domain/Installation';

interface Props {
    data: {
        prismicElectricitegeneralepage: PInstallation;
    }
    pageContext: PageContext;
}


const Installation = ({pageContext, data: { prismicElectricitegeneralepage }}: Props): JSX.Element => {

    const { dataHeader, alternate } = pageContext;
    const { uid, lang, alternate_languages } = prismicElectricitegeneralepage;
    const { seo_url, seo_title, seo_description, seo_keywords } = prismicElectricitegeneralepage.data;
    const { pretitle_presentation, title_presentation, content_presentation, button_presentation, image_presentation } = prismicElectricitegeneralepage.data;
    const { content_presentation2, content2_presentation2, content3_presentation2 } = prismicElectricitegeneralepage.data;

    const selectLang = {
        lang: lang,
        uid: uid,
        alternate_languages: alternate_languages
    };

    const pagePresentation = {
        preTitle: pretitle_presentation.raw,
        title: title_presentation.raw,
        content: content_presentation.raw,
        button: button_presentation.raw,
        image: image_presentation
    }
    const presentation2 = {
        content: content_presentation2,
        content2: content2_presentation2,
        content3: content3_presentation2
    }

    const seo = {
        lang: lang.split('-')[0],
        url: `${lang.split('-')[0]}/${seo_url.text}`,
        title: seo_title.text,
        description: seo_description.text,
        keywords: seo_keywords.text
    }

    const linkAlternate = alternate?.map(data => ({
      lang: data.lang,
      url: `${data.lang.split('-')[0]}/${data.uid}`
    }))

    return (
        <Layout header={dataHeader} selectLang={selectLang} >
            <SEO lang={seo.lang} title={`Global Electrics | ${seo.title}`} url={seo.url} description={seo.description} keywords={seo.keywords} alternate={linkAlternate} />
            <PagePresentation data={pagePresentation} css={css`flex-direction: row-reverse; padding: 2rem 0;`} />
            <InstallationPresentation data={presentation2} />
            <Services lang={lang} />
        </Layout>
    );
};

export const query = graphql`
query ($id: String!) {
  prismicElectricitegeneralepage(id: {eq: $id}) {
    lang
    uid
    alternate_languages {
      lang
      uid
    }
    data {
      pretitle_presentation {
        raw
      }
      title_presentation {
        raw
      }
      content_presentation {
        raw
      }
      image_presentation {
        alt
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      button_presentation {
        raw
      }
      content_presentation2 {
        raw
      }
      content2_presentation2 {
        raw
      }
      content3_presentation2 {
        raw
      }
      seo_url {
        text
      }
      seo_title {
        text
      }
      seo_description {
        text
      }
      seo_keywords {
        text
      }
    }
  }
}

`

export default Installation;
